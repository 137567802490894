// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import $ from "jquery";
import Inputmask from "inputmask";

global.$ = $;
global.jQuery = $;

Rails.start();
ActiveStorage.start();

$(document).on("click", ".vacant__btn", function (e) {
  e.preventDefault();

  $("#popup .vacancy_title").html(
    "&mdash; " + $(this).parent().find(".vacant__title").text()
  );

  $("#response_vacancy_id").val($(this).parent().data("id"));

  $("#popup").css("display", "flex");

  $("#name").focus();

  parent.postMessage(
    {
      event: "openPopup",
      size: $("body").height(),
      popupHeight: $("#popup .b-popup-content").height(),
    },
    "*"
  );
});

$(document).on("click", ".close-form, .cls-btn", function (e) {
  e.preventDefault();

  $(this).parents(".b-popup").hide();
});

$(document).ready(function () {
  date.max = new Date().toISOString().split("T")[0];

  Inputmask({ alias: "email", clearIncomplete: true }).mask(
    document.getElementById("response_email")
  );
  Inputmask({ mask: "+7 (999) 999 99-99", clearIncomplete: true }).mask(
    document.getElementById("response_phone")
  );
});

$(document).on("change", "#file", function () {
  if ($("#file")[0].files[0])
    $("#file").parent().find("> span").text($("#file")[0].files[0].name);
  else $("#file").parent().find("> span").text("Загрузить файл");
});

$(window).on("resize", function () {
  parent.postMessage({ event: "resizeForm", size: $("body").height() }, "*");
});
$(document).ready(function () {
  parent.postMessage({ event: "resizeForm", size: $("body").height() }, "*");
});
